import React from 'react';

import bannerImage from '../assets/banner-1.svg';
import bgImage from '../assets/bg-1.svg';
import { G_FEATURES } from '../static';


const Banner = (props) => {
  return (
    <>
      <main className="max-w-md mx-auto  overflow-hidden md:max-w-7xl">
        <img
          src={bgImage}
          className="max-w-none absolute left-0 transform-x-[100] top-1/2 h-1/3"
          alt="Background"
        />
        <div className="md:flex p-10 justify-between">
          <div className="py-6 md:justify-start md:space-x-12">
            <p className="text-black-500 text-center md:text-left sm:max-w-xl md:mt-5 text-3xl md:text-4xl leading-relaxed md:leading-relaxed lg:mx-0">
              We're one among the best pioneering company with experienced team
              of professionals providing{" "}
              <span className="text-green-500 font-semibold">
                <TextAnimation features={G_FEATURES} />
              </span>
            </p>
          </div>
          <div className="md:flex-shrink-0">
            <img
              className="object-fill h-80"
              src={bannerImage}
              alt="."
            />
          </div>
        </div>
      </main>
    </>
  );
};

export const TextAnimation = (props) => {
  const { features } = props;
  const [featuresIndex, setFeaturesIndex] = React.useState(0);

  React.useEffect(() => {
    let timeout;
    if (featuresIndex <= features.length - 1) {
      timeout = setTimeout(() => setFeaturesIndex(featuresIndex + 1), 3000);
    }
    if (featuresIndex === features.length) {
      setFeaturesIndex(0);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [features, featuresIndex]);

  return <>{features[featuresIndex]}</>;
};

export default Banner;
