// import ln from "../assets/social/ln.svg";

// const socialMediaLinks = [
//   // {
//   //   type: "fb",
//   //   link: "",
//   // },
//   // {
//   //   type: "tw",
//   //   link: "",
//   // },
//   // {
//   //   type: "in",
//   //   link: "",
//   // },
//   {
//     icon: ln,
//     title: "Linkedin - SISCASO",
//     link: "https://www.linkedin.com/company/siscaso",
//   },
// ];

const Footer = (props) => {
  return (
    <>
      <div className="md:box-content bg-gradient-to-r from-blue-400 to-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 p-4 text-center md:text-left ">
          <div className="flex justify-between">
            <a href="/">
              <img
                className="bg-indigo-1000 img-color h-8 filter brightness-0 invert"
                src="logo.svg"
                alt="SISCASO"
              />
            </a>
            <div className="flex space-x-5 items-center">
              {/* {socialMediaLinks.map((item, idx) => {
                return (
                  <a key={idx} href={item.link} className="">
                    <img
                      className="bg-indigo-1000 img-color h-4 filter brightness-0 invert"
                      src={item.icon}
                      alt={item.title}
                    />
                  </a>
                );
              })} */}
              <span className="text-white italic ">&#169; SISCASO Pvt Ltd. All rights reserved</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
