import React from "react";

const PrivacyPolicy = (props) => {
  return (
    <div
      className="container mx-auto p-10"
      style={{ lineHeight: "2rem" }}
      id="privacypolicy"
    >
      <div>
        <h1 s class="text-4xl font-bold mb-6 text-center">
          <span class="border-b-2 border-blue-500 pb-2">Privacy Policy</span>
        </h1>
        <p>
          <span>
            <strong>SISCASO</strong> ("we," "our," or "us") is committed to
            protecting your privacy. This Privacy Policy explains how your
            personal information is collected, used and disclosed by us.&nbsp;
          </span>
        </p>
        <p>
          <span>
            This Privacy Policy applies to our website, <a href="https://www.siscaso.in/">https://www.siscaso.in/</a>,
            and its associated subdomains (collectively, our "Service").
          </span>
        </p>
        <p>
          <span>
            By accessing or using our Service, you signify that you have read,
            understood and agree to our collection, storage, use and disclosure
            of your personal information as described in this Privacy Policy and
            our Terms of Service.
          </span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>Definitions and key terms</span>
        </h1>
        <p>
          <span>
            To help explain things as clearly as possible in this Privacy
            Policy, every time any of these terms are referenced, they are
            strictly defined as:
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <ul class="list-disc pl-4 mb-4">
          <li>
            <span>
              <strong>Cookie:</strong> A small amount of data generated by a
              website and saved by your web browser. It is used to identify your
              browser, provide analytics and remember information about you,
              such as your language preference or login information.
            </span>
          </li>
          <li>
            <span>
              <strong>Company:</strong> When this policy mentions
              &ldquo;Company,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or
              &ldquo;our,&rdquo; it refers to SISCASO, Chennai, Tamil Nadu,
              which is responsible for your information under this Privacy
              Policy.&nbsp;
            </span>
          </li>
          <li>
            <span>
              <strong>Country:</strong> Where SISCASO or the owners/founders of
              SISCASO are based, in this case, is India.&nbsp;
            </span>
          </li>
          <li>
            <span>
              <strong>Customer:</strong> Refers to the company, organization or
              person that signs up to use the SISCASO Service to manage the
              relationships with your consumers or service users.&nbsp;
            </span>
          </li>
          <li>
            <span>
              <strong>Device:</strong> Any internet-connected device such as a
              phone, tablet, computer or any other device that can be used to
              visit SISCASO and use the services.&nbsp;
            </span>
          </li>
          <li>
            <span>
              <strong>IP address:</strong> Every device connected to the
              Internet is assigned a number known as an Internet Protocol (IP)
              address. These numbers are usually assigned in geographic blocks.
              An IP address can often be used to identify the location from
              which a device is connecting to the Internet.&nbsp;
            </span>
          </li>
          <li>
            <span>
              <strong>Personnel:</strong> Refers to those individuals who are
              employed by SISCASO or are under contract to perform a service on
              behalf of one of the parties.&nbsp;
            </span>
          </li>
          <li>
            <span>
              <strong>Personal Data:</strong> Any information that directly,
              indirectly, or in connection with other information &mdash;
              including a personal identification number &mdash; allows for the
              identification or identifiability of a natural person.&nbsp;
            </span>
          </li>
          <li>
            <span>
              <strong>Service:</strong> Refers to the service provided by SISCASO as described in the relative terms (if available) and on
              this platform.&nbsp;
            </span>
          </li>
          <li>
            <span>
              <strong>Third-party service:</strong> Refers to advertisers,
              contest sponsors, promotional and marketing partners and others
              who provide our content or whose products or services we think may
              interest you.&nbsp;
            </span>
          </li>
          <li>
            <span>
              <strong>Website:</strong> SISCASO&rsquo;s site can be
              accessed via this URL: <a href="https://www.siscaso.in/">https://www.siscaso.in/</a>.&nbsp;
            </span>
          </li>
          <li>
            <span>
              <strong>You:</strong> A person or entity that is registered with
              SISCASO to use the Services.
            </span>
          </li>
        </ul>
        <p>&nbsp;</p>
        <p class="text-3xl font-bold mb-6">
          <span>What Information Do We Collect?</span>
        </p>
        <ul class="list-disc pl-4 mb-4">
          <li>
            <span>
              We collect information from you when you visit our service,
              register, place an order, subscribe to our newsletter, respond to
              a survey or fill out a form. The information we collect includes:
            </span>
          </li>
          <li>
            <span>Name/Username</span>
          </li>
          <li>
            <span>Email Address</span>
          </li>
          <li>
            <span>Contact Number</span>
          </li>
          <li>
            <span>Department &amp; Institute Details etc.</span>
          </li>
          <li>
            <span>
              We also collect information from mobile devices to enhance the
              user experience, although these features are completely optional:
            </span>
          </li>
          <li>
            <span>
              Camera (Pictures): Granting Camera permission allows the user to
              upload any picture directly from our service. You can safely deny
              camera permissions for our service.
            </span>
          </li>
          <li>
            <span>
              Microphone (audio): Granting Microphone permission allows the user
              to upload responses to speaking type questions directly from our
              service. You can safely deny microphone permissions for our
              service.
            </span>
          </li>
        </ul>
        <p>
          <span>&nbsp;</span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>How Do We Use The Information We Collect?</span>
        </h1>
        <ul class="list-disc pl-4 mb-4">
          <li>
            <span>
              Any of the information we collect from you may be used in one of
              the following ways:
            </span>
          </li>
          <li>
            <span>
              To personalize your experience (your information helps us to
              better respond to your individual needs)
            </span>
          </li>
          <li>
            <span>
              To improve our service (we continually strive to enhance our
              service offerings based on the information and feedback we receive
              from you)
            </span>
          </li>
          <li>
            <span>
              To improve customer service (your information helps us to more
              effectively respond to your customer service requests and support
              needs)
            </span>
          </li>
          <li>
            <span>To process transactions</span>
          </li>
          <li>
            <span>
              To administer a contest, promotion, survey, or other site feature
            </span>
          </li>
          <li>
            <span>To send periodic emails</span>
          </li>
        </ul>
        <p>
          <span>&nbsp;</span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>When do we use customer information from third parties?</span>
        </h1>
        <p>
          <span>
            We receive some information from third parties when you contact us.
            For example, when you submit your email address to show interest in
            becoming our customer, we receive information from a third party
            that provides automated fraud detection services to us. We also
            occasionally collect information that is publicly available on
            social media websites. You can control how much of your information
            social media websites make public by visiting these websites and
            changing your privacy settings.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>
            Do we share the information we collect with third parties?
          </span>
        </h1>
        <p>
          <span>
            We may share the information that we collect, both personal and
            non-personal, with third parties such as advertisers, contest
            sponsors, promotional and marketing partners and others who provide
            our content or whose products or services we think may interest you.
            We may also share it with our current and future affiliated
            companies and business partners. If we are involved in a merger,
            asset sale or other business reorganization, we may also share or
            transfer your personal and non-personal information to our
            successors-in-interest.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            We may engage trusted third-party service providers to perform
            functions and provide services to us, such as hosting and
            maintaining our servers and our service, database storage and
            management, email management, storage marketing, credit card
            processing, customer service and fulfilling orders for products and
            services you may purchase through our service. We will likely share
            your personal information and possibly some non-personal
            information, with these third parties to enable them to perform
            these services for us and for you.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            We may share portions of our log file data, including IP addresses,
            for analytics purposes with third parties such as web analytics
            partners, application developers and ad networks. If your IP
            address is shared, it may be used to estimate general location and
            other technographics such as connection speed, whether you have
            visited the service in a shared location and the type of device used
            to visit the service. They may aggregate information about our
            advertising and what you see on the service and then provide
            auditing, research and reporting for us and our advertisers.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            We may also disclose personal and non-personal information about you
            to government or law enforcement officials or private parties as we,
            in our sole discretion, believe necessary or appropriate in order to
            respond to claims, legal process (including subpoenas), to protect
            our rights and interests or those of a third party, the safety of
            the public or any person, to prevent or stop any illegal, unethical
            or legally actionable activity or to otherwise comply with
            applicable court orders, laws, rules and regulations.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            &nbsp;Where and when is information collected from customers and end
            users?
          </span>
        </p>
        <p>
          <span>
            We will collect personal information that you submit to us. We may
            also receive personal information about you from third parties as
            described above.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>Is the information collected through our service secure?</span>
        </h1>
        <p>
          <span>
            We take precautions to protect the security of your information. We
            have physical, electronic and managerial procedures to help
            safeguard, prevent unauthorized access, maintain data security and
            correctly use your information. However, neither people nor security
            systems are foolproof, including encryption systems. Additionally,
            people can commit intentional crimes, make mistakes or fail to
            follow policies. Therefore, while we make reasonable efforts to
            protect your personal information, we cannot guarantee its absolute
            security. If applicable law imposes any non-disclaimable duty to
            protect your personal information, you agree that intentional
            misconduct will be the standard used to measure our compliance with
            that duty.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>Can I update or correct my information?</span>
        </h1>
        <p>
          <span>
            The rights you have to request updates or corrections to the
            information we collect depend on your relationship with us.
            Personnel may update or correct their information as detailed in our
            internal company employment policies. Customers have the right to
            request the restriction of certain uses and disclosures of
            personally identifiable information as follows. You can contact us
            in order to:
          </span>
        </p>
        <ol class="list-decimal mb-4 pl-4">
          <li>
            <span>
              {" "}
              Update or correct your personally identifiable information,
            </span>
          </li>
          <li>
            <span>
              {" "}
              Change your preferences with respect to communications and other
              information you receive from us or
            </span>
          </li>
          <li>
            <span>
              {" "}
              Delete the personally identifiable information maintained about
              you on our systems (subject to the following paragraph), by
              canceling your account.
            </span>
          </li>
        </ol>
        <p>
          <span>
            Such updates, corrections, changes and deletions will have no
            effect on other information that we maintain or information that we
            have provided to third parties in accordance with this Privacy
            Policy prior to such update, correction, change or deletion. To
            protect your privacy and security, we may take reasonable steps
            (such as requesting a unique password) to verify your identity
            before granting you profile access or making corrections. You are
            responsible for maintaining the secrecy of your unique password and
            account information at all times.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            You should be aware that it is not technologically possible to
            remove each and every record of the information you have provided to
            us from our system. The need to back up our systems to protect
            information from inadvertent loss means that a copy of your
            information may exist in a non-erasable form that will be difficult
            or impossible for us to locate. Promptly after receiving your
            request, all personal information stored in databases we actively
            use and other readily searchable media will be updated, corrected,
            changed or deleted, as appropriate, as soon as and to the extent
            reasonably and technically practicable. If you are an end-user and
            wish to update, delete or receive any information we have about you,
            you may do so by contacting the organization of which you are a
            customer.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>&nbsp;</span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>Personnel</span>
        </h1>
        <p>
          <span>
            If you are one of our workers or applicants, we collect information
            you voluntarily provide to us. We use the information collected for
            Human Resources purposes in order to administer benefits to workers
            and screen applicants. You may contact us in order to:
          </span>
        </p>
        <ol class="list-decimal mb-4 pl-4">
          <li>
            <span> Update or correct your information,</span>
          </li>
          <li>
            <span>
              {" "}
              Change your preferences with respect to communications and other
              information you receive from us or
            </span>
          </li>
          <li>
            <span>
              {" "}
              Receive a record of the information we have relating to you.
            </span>
          </li>
        </ol>
        <p>
          <span>
            Such updates, corrections, changes and deletions will have no effect
            on other information that we maintain or information that we have
            provided to third parties in accordance with this Privacy Policy
            prior to such update, correction, change or deletion.
          </span>
        </p>
        <p>&nbsp;</p>
        <p class="text-2xl font-bold mb-4">
          <span>How Long Do We Keep Your Information?</span>
        </p>
        <p>
          <span>
            We keep your information only for as long as we need it to provide
            service to you and fulfill the purposes described in this policy.
            This also applies to anyone with whom we share your information and
            who carries out services on our behalf. When we no longer need to
            use your information and there is no need for us to keep it to
            comply with our legal or regulatory obligations, we'll either remove
            it from our systems or depersonalize it so that we can't identify
            you.
          </span>
        </p>
        <p>&nbsp;</p>
        <p class="text-2xl font-bold mb-4">
          <span>How Do We Protect Your Information?</span>
        </p>
        <p>
          <span>
            We implement a variety of security measures to maintain the safety
            of your personal information when you place an order or enter,
            submit or access your personal information. We offer the use of a
            secure server. All supplied sensitive/credit information is
            transmitted via Secure Socket Layer (SSL) technology and then
            encrypted into our Payment gateway provider's database only to be
            accessible by those authorized with special access rights to such
            systems and are required to keep the information confidential. After
            a transaction, your private information (credit cards, social
            security numbers, financials etc.) is never kept on file. However,
            we cannot ensure or warrant the absolute security of any information
            you transmit to us or guarantee that your information on the Service
            may not be accessed, disclosed, altered or destroyed by a breach of
            any of our physical, technical or managerial safeguards.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>&nbsp;</span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>Governing Law</span>
        </h1>
        <p>
          <span>
            The laws of India, excluding its conflicts of law rules, shall
            govern this Agreement and your use of our service. Your use of our
            service may also be subject to other local, state, national or
            international laws.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>Your Consent</span>
        </h1>
        <p>
          <span>
            By using our service, registering an account or making a purchase,
            you consent to this Privacy Policy.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>Links to Other Websites</span>
        </h1>
        <p>
          <span>
            This Privacy Policy applies only to the Services. The Services may
            contain links to other websites not operated or controlled by us. We
            are not responsible for the content, accuracy or opinions expressed
            on such websites and they are not investigated, monitored or checked
            for accuracy or completeness by us. Please remember that when you
            use a link to navigate from the Services to another website, our
            Privacy Policy is no longer in effect. Your browsing and interaction
            on any other website, including those linked on our platform, are
            subject to that website&rsquo;s own rules and policies. Such third
            parties may use their own cookies or other methods to collect
            information about you.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>Cookies</span>
        </h1>
        <p>
          <span>
            We use "Cookies" to identify the areas of our website that you have
            visited. A Cookie is a small piece of data stored on your computer
            or mobile device by your web browser. We use Cookies to personalize
            the Content that you see on our website. Most web browsers can be
            set to disable the use of Cookies. However, if you disable Cookies,
            you may not be able to access functionality on our website correctly
            or at all. We never place Personally Identifiable Information in
            Cookies.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>Changes To Our Privacy Policy</span>
        </h1>
        <p>
          <span>
            If we decide to change our privacy policy, we will post those
            changes on this page and/or update the Privacy Policy modification
            date below.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>Third-Party Services</span>
        </h1>
        <p>
          <span>
            We may display, include or make available third-party content
            (including data, information, applications and other
            products/services) or provide links to third-party websites or
            services ("Third-Party Services"). You acknowledge and agree that we
            shall not be responsible for any Third-Party Services, including
            their accuracy, completeness, timeliness, validity, copyright
            compliance, legality, decency, quality or any other aspect thereof.
            We do not assume and shall not have any liability or responsibility
            to you or any other person or entity for any Third-Party Services.
            Third-Party Services and links thereto are provided solely as a
            convenience to you and you access and use them entirely at your own
            risk and subject to such third parties' terms and conditions.&nbsp;
          </span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>Tracking Technologies</span>
        </h1>
        <p class="text-2xl font-bold mb-4">
          <span>Cookies</span>
        </p>
        <p>
          <span>
            We use cookies to enhance the performance and functionality of our
            service, but they are non-essential to its use. However, without
            these cookies, certain functionalities like videos may become
            unavailable or you would be required to enter your login details
            every time you visit our service, as we would not be able to
            remember that you had logged in previously.
          </span>
        </p>
        <p class="text-2xl font-bold mb-4">
          <span>Local Storage</span>
        </p>
        <p>
          <span>
            Local Storage, sometimes known as DOM storage, provides web apps
            with methods and protocols for storing client-side data. Web storage
            supports persistent data storage, similar to cookies but with
            greatly enhanced capacity and no information stored in the HTTP
            request header.
          </span>
        </p>
        <p class="text-2xl font-bold mb-4">
          <span>Sessions</span>
        </p>
        <p>
          <span>
            We use "Sessions" to identify the areas of our website that you have
            visited. A session is a small piece of data stored on your computer
            or mobile device by your web browser.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <h1 class="text-3xl font-bold mb-6">
          <span>Contact Us</span>
        </h1>
        <ul class="list-disc pl-4 mb-4">
          <li>
            <span>
              Don't hesitate to contact us if you have any questions, via email:{" "}
            </span>
            <a href="mailto:contact@siscaso.in ">contact@siscaso.in </a>.
          </li>
        </ul>
        <p>&nbsp;</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
