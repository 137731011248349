import { CheckCircleIcon } from '@heroicons/react/outline';

import { G_FEATURES } from '../static';

const Features = (props) => {
  return (
    <>
      <div id="services" className="py-12 md:box-content mt-12 bg-gradient-to-r from-blue-400 to-green-400">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="m-10">
            <ul className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-8">
              {G_FEATURES.map((feature, idx) => (
                <li key={idx} className="flex align-middle gap-x-4">
                  <CheckCircleIcon className="w-8 text-white" />{" "}
                  <span className="text-white text-xl">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
