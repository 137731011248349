import bgImage from "../assets/bg-1.svg";

const Services = (props) => {
  return (
    <>
      <div className="relative w-full overflow-hidden">
        <img
          src={bgImage}
          className="max-w-none absolute -right-10 top-10 transform-x-[100] h-48"
          alt="Background"
        />
        <div className="max-w-md mx-auto overflow-hidden md:max-w-7xl">
          <div className="flex flex-col md:flex-row p-10 align-middle items-center">
            <div className="py-6 md:space-x-12 ">
              <p className="text-black-500 text-center md:text-left sm:max-w-3xl md:mt-5 text-xl md:text-2xl leading-relaxed md:leading-relaxed lg:mx-0">
                IT Infrastructure Supply and Services, Data Validation and
                management. Online Assessment Tools. Skill Development Products
                for Schools and Colleges, and assisting Educational
                Institutions/Universities/Councils in understanding the
                potential of their students, and ascertain the caliber of the
                students with respect to the required level of knowledge and
                skill for employment by leading organizations.
              </p>
            </div>
            <div className="py-6 mx-auto order-first md:order-last md:flex md:align-middle md:justify-center text-center">
              <p className="text-4xl font-semibold">Services</p>
            </div>
          </div>
        </div>
        <img
          src={bgImage}
          className="max-w-none absolute -left-10 -bottom-16 h-64"
          alt="Background"
        />
      </div>
    </>
  );
};

export default Services;
