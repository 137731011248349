import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Banner from "./content/banner";
import Contact from "./content/contact";
import Footer from "./content/footer";
import Header from "./content/header";
import Services from "./content/services";
import Features from "./content/features";
import PrivacyPolicy from "./content/privacy";

const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <>
            <div className="App">
              <Header />
              <Banner />
              <Features />
              <Services />
              <Contact />
              <Footer />
            </div>
          </>
        } />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
