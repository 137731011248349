const Contact = (props) => {
  return (
    <>
      <div id="contact" className="py-12 md:box-content mt-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 p-10 text-center md:text-left">
          <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-8 items-center">
            <div className="text-4xl font-semibold">Contact</div>
            <div>
              <p className="font-semibold">Corporate Office </p>
              <Address />
            </div>
            <div>
              <p className="font-semibold">Reach us </p>
              <a href="mailto:contact@siscaso.in" className="block mb-10 text-green-600">contact@siscaso.in</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Address = (props) => {
  return (
    <address className="not-italic leading-relaxed">
      {/* No. 36, Balaji Nagar 1<sup>st</sup> Street
      <br />
      Royapettah Chennai - 600014 */}
      No. 16/21, 2<sup>nd</sup> Floor, Cenotaph Road, 1st Street, 
      <br />
      Alwarpet, Chennai - 600018
    </address>
  );
};

export default Contact;
